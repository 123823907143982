window.htmx = require("htmx.org");

import { Modal, Offcanvas, Popover, Toast, Tooltip } from "bootstrap";
import Choices from "choices.js";
import Alpine from "alpinejs";
import * as pdfjsLib from "pdfjs-dist";

pdfjsLib.GlobalWorkerOptions.workerSrc = "/static/s/js/pdf.worker.mjs";

window.Modal = Modal;
window.Alpine = Alpine;

document.addEventListener("alpine:init", () => {
  var shownDocument = null;

  Alpine.data("pdfViewer", () => ({
    pageNumber: 1,
    totalPages: 1,
    scale: 1.25,
    _pageRendering: false,
    _pageNumPending: false,
    documentUrl: null,

    renderTarget() {
      return {
        ["x-ref"]: "canvasElement",
      };
    },

    loadDocument(documentUrl) {
      this.documentUrl = documentUrl;
      const loadingTask = pdfjsLib.getDocument(documentUrl);
      debugger;
      loadingTask.promise.then((pdf) => {
        this.clean();
        shownDocument = pdf;
        this.displayPage(pdf, this.pageNumber);
      });
    },
    displayPage(pdf, num) {
      if (!shownDocument) return;
      this.isPageRendering = true;
      this.totalPages = shownDocument.numPages;

      shownDocument.getPage(num).then((page) => {
        const viewport = page.getViewport({ scale: this.scale });
        const context = this.$refs.canvasElement.getContext("2d");
        this.$refs.canvasElement.height = viewport.height;
        this.$refs.canvasElement.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        const renderTask = page.render(renderContext);

        renderTask.promise.then(() => {
          this.isPageRendering = false;
          if (this._pageNumPending !== null) {
            this.displayPage(pdf, this.pageNumber);
            this._pageNumPending = null;
          }
        });
      });
    },

    queueRenderPage(num) {
      if (this._pageRendering) {
        this._pageNumPending = num;
      } else {
        this.displayPage(shownDocument, num);
      }
    },

    get canGoNext() {
      return this.pageNumber < this.totalPages;
    },

    get canGoPrevious() {
      return this.pageNumber > 1;
    },

    nextButton() {
      return {
        ["x-on:click"]() {
          if (!this.canGoNext) return;
          this.pageNumber++;
          this.queueRenderPage(this.pageNumber);
        },
        [":disabled"]() {
          return !this.canGoNext;
        },
      };
    },

    clean() {
      shownDocument = null;
      this.pageNumber = 1;
      this.totalPages = 1;
      this.scale = 1.25;
      this.isPageRendering = false;
      this._pageNumPending = false;
    },

    prevButton() {
      return {
        ["x-on:click"]() {
          if (!this.canGoPrevious) return;
          this.pageNumber--;
          this.queueRenderPage(this.pageNumber);
        },
        [":disabled"]() {
          return !this.canGoPrevious;
        },
      };
    },

    zoomInButton() {
      return {
        ["x-on:click"]() {
          this.scale += 0.25;
          this.displayPage(shownDocument, this.pageNumber);
        },
      };
    },

    zoomOutButton() {
      return {
        ["x-on:click"]() {
          if (this.scale < 0.75) return;
          this.scale -= 0.25;
          this.displayPage(shownDocument, this.pageNumber);
        },
      };
    },
  }));
  Alpine.data("actionMessage", () => ({
    shown: false,
    timeout: null,

    display() {
      if (!this.$el.dataset.targetId) {
        console.error("Missing required attribute: data-target-id");
      }

      if (this.$el.dataset.targetId == this.$event.target.id) {
        clearTimeout(this.timeout);
        this.shown = true;
        this.timeout = setTimeout(() => {
          this.shown = false;
        }, 200);
      }
    },

    content: {
      ["x-show.transition.out.opacity.duration.1500ms"]: "shown",
      ["x-transition:leave.opacity.duration.1500ms"]: "",
      ["x-on:htmx:after-swap.window"]() {
        this.display();
      },

      [":style"]() {
        return { display: "none" };
      },
    },
  }));

  Alpine.data("downloadTrigger", () => ({
    download(filename, event) {
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:application/pdf;charset=utf-8, ${encodeURIComponent(event.detail.xhr.response)}`, // Get the pdf from the response of the server
      );
      element.setAttribute("download", filename);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  }));

  Alpine.data("tabBar", () => ({
    tabSwitch: {
      ["x-on:htmx:after-on-load"]() {
        const currentTab = this.$root.querySelector("[aria-selected=true]");
        currentTab.setAttribute("aria-selected", "false");
        currentTab.classList.remove("active");
        const newActiveTab = this.$event.target;
        newActiveTab.setAttribute("aria-selected", "true");
        newActiveTab.classList.add("active");
      },
    },
  }));

  Alpine.data("toggleablePasswordInput", () => ({
    visible: false,

    passwordToggle: {
      ["x-on:click"]() {
        if (this.visible) {
          this.$refs.passwordInput.type = "password";
        } else {
          this.$refs.passwordInput.type = "text";
        }
        this.visible = !this.visible;
      },
    },
  }));

  Alpine.data("countryChoices", () => ({
    init() {
      new Choices(this.$root, { itemSelectText: "" });
    },
  }));

  Alpine.data("confirmationModal", (confirmationModalSelector) => ({
    confirmationTrigger: {
      ["x-on:click"]() {
        const confirmationModal = Modal.getOrCreateInstance(
          confirmationModalSelector,
        );
        confirmationModal.show();

        const handleConfirmation = () => {
          htmx.trigger(this.$el, "confirmed");
        };
        const confirmationModalEl = document.querySelector(
          confirmationModalSelector,
        );
        const confirmationButton = confirmationModalEl.querySelector(
          "button[data-confirmation-button]", // All confirmation modal button must have this attribute
        );
        confirmationButton.addEventListener(
          "click",
          function confirmationButtonEventListener() {
            handleConfirmation();
            confirmationButton.removeEventListener(
              "click",
              confirmationButtonEventListener,
            );
            confirmationModal.hide();
          },
        );
      },
    },
  }));

  Alpine.data("toggeableInput", (initialSelectedOption = false) => ({
    selectedOption: initialSelectedOption,

    toggleTrigger: {
      ["x-model"]: "selectedOption",
    },
    toggledInput(disabledValues = "False") {
      return {
        [":disabled"]() {
          return disabledValues.split(",").includes(this.selectedOption);
        },
      };
    },
  }));
  Alpine.data("downloadTrigger", () => ({
    download(filename, event) {
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:application/pdf;charset=utf-8, ${encodeURIComponent(event.detail.xhr.response)}`, // Get the pdf from the response of the server
      );
      element.setAttribute("download", filename);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  }));

  Alpine.data("tabBar", () => ({
    tabSwitch: {
      ["x-on:htmx:after-on-load"]() {
        const currentTab = this.$root.querySelector("[aria-selected=true]");
        currentTab.setAttribute("aria-selected", "false");
        currentTab.classList.remove("active");
        const newActiveTab = this.$event.target;
        newActiveTab.setAttribute("aria-selected", "true");
        newActiveTab.classList.add("active");
      },
    },
  }));

  Alpine.data("executiveActions", () => ({
    toggleVisualizationModal: {
      ["x-on:click"]() {
        this.$dispatch("open-modal", {
          link: this.$el.dataset.link,
          modalTitle: this.$el.dataset.modalTitle,
        });
      },
    },
  }));

  Alpine.data("executiveActions", () => ({
    toggleVisualizationModal: {
      ["x-on:click"]() {
        this.$dispatch("open-modal", { link: this.$el.dataset.link });
      },
    },
  }));

  Alpine.data("toggleablePasswordInput", () => ({
    visible: false,

    passwordToggle: {
      ["x-on:click"]() {
        if (this.visible) {
          this.$refs.passwordInput.type = "password";
        } else {
          this.$refs.passwordInput.type = "text";
        }
        this.visible = !this.visible;
      },
    },
  }));

  Alpine.data("sumComponent", () => ({
    addendOne: 0,
    addendTwo: 0,

    init() {
      this.addendOne = this.$el.dataset.addendOne * 1;
      this.addendTwo = this.$el.dataset.addendTwo * 1;
    },
    sum: {
      [":value"]() {
        return 1 * this.addendOne + 1 * this.addendTwo;
      },
    },
  }));

  Alpine.data("tabBar", () => ({
    tabSwitch: {
      ["x-on:htmx:after-on-load"]() {
        const currentTab = this.$root.querySelector("[aria-selected=true]");
        currentTab.setAttribute("aria-selected", "false");
        currentTab.classList.remove("active");
        const newActiveTab = this.$event.target;
        newActiveTab.setAttribute("aria-selected", "true");
        newActiveTab.classList.add("active");
      },
    },
  }));

  Alpine.data("inalienabilityComponent", () => ({
    state: "yes",
    duration: 0,
    init() {
      this.state = this.$el.dataset.state;
      this.duration = 1 * this.$el.dataset.duration;
    },
    change: {
      [":value"]() {
        return this.state === "no" ? 0 : 1 * this.duration;
      },
      [":readonly"]() {
        return this.state === "no";
      },
    },
  }));

  Alpine.data("roleComponent", () => ({
    role: "manager",
    actions: 0,
    total: 0,
    mandate: 0,
    version: "v1",
    init() {
      this.role = this.$el.dataset.role;
      this.actions = 1 * this.$el.dataset.actions;
      this.total = 1 * this.$el.dataset.total;
      this.mandate = 1 * this.$el.dataset.mandate;
      this.version = this.$el.dataset.version;
    },
    mandateChange: {
      [":readonly"]() {
        return this.role === "partner";
      },
      [":value"]() {
        return this.role === "partner" ? 0 : 1 * this.mandate;
      },
    },
    actionsChange: {
      [":readonly"]() {
        return this.role === "manager" || this.version === "v1";
      },
      [":value"]() {
        return this.role === "manager" ? 0 : 1 * this.total;
      },
    },
  }));
  Alpine.data("officeComponent", () => ({
    officeRented: "yes",
    rentAmount: 0,
    init() {
      this.officeRented = this.$el.dataset.officeRented;
      this.rentAmount = 1 * this.$el.dataset.rentAmount;
    },
    officeRentedChange: {
      [":readonly"]() {
        return this.officeRented === "no";
      },
      [":value"]() {
        return this.officeRented === "no" ? 0 : 1 * this.rentAmount;
      },
    },
  }));

  Alpine.data("switchComponent", (enableValue, disableValue) => ({
    state: enableValue,
    init() {
      this.state = this.$el.dataset.state;
    },
    change: {
      [":disabled"]() {
        return this.state === disableValue;
      },
    },
  }));
  Alpine.data("formLayoutToggle", () => ({
    toggleValue: null,
    init() {
      if (!("initialValue" in this.$root.dataset)) {
        console.warn(
          "Missing attribute on formLayoutToggle element: data-initial-value. Set to '' by default.",
        );
      }
      const initialToggleValue = this.$root.dataset.initialValue || "";
      this.toggleValue = initialToggleValue;
    },
    formLayout: {
      ["x-show"]() {
        if (!("activeValue" in this.$el.dataset)) {
          console.warn(
            "Missing attribute on formLayout element: data-active-value. Set to '' by default.",
          );
        }
        const activeValue = this.$el.dataset.activeValue || "";
        return activeValue === this.toggleValue;
      },
    },
    formToggleTrigger: {
      ["x-model"]: "toggleValue",
    },
  }));
  Alpine.data("openNotificationMessage", () => ({
    async init() {
      const notifMessage = new Modal(this.$el, {
        keyboard: false
      })
      notifMessage.show();
      await this.closeModalAuto(notifMessage)
    },
    closeModalAuto(notifMessage) {
      return new Promise((resolve) => {
        setTimeout(() => {
          notifMessage.hide();
          resolve();
        }, 4000);
      });
    }

  }));
  Alpine.data("modalDeleteDocument", () => ({
    modalDelete: null,
    init() {
        this.modalDelete = new Modal(this.$el, {
            keyboard: false})
    },
    showModal() {
        this.modalDelete.show();
    },
    hideModal() {
        this.modalDelete.hide();
    }
}));

});

Alpine.start();

Array.from(document.querySelectorAll(".offcanvas")).forEach(
  (offcanvasNode) => new Offcanvas(offcanvasNode),
);

document.addEventListener("htmx:load", () => {
  const popoverTriggerList = document.querySelectorAll(
    '[data-bs-toggle="popover"]',
  );
  const _popoverList = [...popoverTriggerList].map(
    (popoverTriggerEl) => new Popover(popoverTriggerEl),
  );
  const toastElList = document.querySelectorAll(".toast");
  const _toastList = [...toastElList].map((toastEl) => new Toast(toastEl));

  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const _tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
});

const showToasts = () => {
  const toastElList = document.querySelectorAll(".toast");
  const toastList = [...toastElList].map((toastEl) =>
    Toast.getOrCreateInstance(toastEl),
  );
  toastList.forEach((el) => {
    el.show();
  });
};

document.addEventListener("htmx:afterSettle", showToasts);
document.addEventListener('DOMContentLoaded',showToasts);




